import { createContext, ReactNode, useContext } from 'react';
import { ICredentialsService, useCredentialsService } from '@services';

export const AuthContext = createContext<ICredentialsService | null>(null);

export function AuthProvider({ children }: { children: ReactNode }) {
  const credentialsService = useCredentialsService();
  return (
    <AuthContext.Provider value={credentialsService}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  const authContext = useContext(AuthContext);

  if (authContext === null) {
    throw new Error('The useAuth hook must be used within an AuthProvider.');
  }

  return authContext;
};
